<template>
<b-card  no-body class="mb-0 pb-2">
  <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
  <div>
    <div class="m-2">
    <b-row class="mt-1">
      <b-col cols="12" md="4"            
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
             <div class="d-flex align-items-center justify-content-end">
                  <b-form-input v-model='filter' class="d-inline-block mr-1" placeholder="Search..." @input="handleSearch"/>
             </div>
      </b-col>
       <!-- PAGINATION DROPDOWN -->
      <b-col cols="12" md="8" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">         
          <v-select
            v-model="perPage"
            style="width: 90px"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
      </b-col>
      <b-col cols="12" md="12" class="d-flex align-items-center justify-content-end mt-1 mb-md-0">
         <div class="d-flex status_top_line">
            <ul class="d-flex align-items-center">
              <li><span class="panding-bg round_small_status"></span><span>Pending</span></li>
              <li><span class="noteligible-bg round_small_status"></span><span>Not Eligible</span></li>
               <li><span class="inprogress-bg round_small_status"></span><span>In progress</span></li>
               <li><span class="paymentPending-bg round_small_status"></span><span>Payment Pending</span></li>
               <li><span class="paymentSuccessfull-bg round_small_status"></span><span>Payment Successful</span></li>
               <li><span class="applicationSubmitted-bg round_small_status"></span><span>Application Submitted</span></li>
                <li><span class="ad_success round_small_status"></span><span>Admission Offered</span></li>
                 <li><span class="ad_rejected round_small_status"></span><span>Rejection</span></li>
            </ul>
          </div>
      </b-col>
    </b-row>
    </div>
           <b-table
           :busy="isFetching"
            striped
            hover
            responsive
            show-empty
            empty-text="No matching records found"
            :per-page="perPage"
            no-local-sorting
            @sort-changed="handleSortChange"
            :current-page="1"
            :items="allApplications"
            :fields="columns"       
            v-model="docCategoryTable"
            @row-clicked="handleRowClick" 

            
            class="position-relative mt-1 application_mgmt_table padding-custom"
           >
           <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner variant="primary" large/>
            </div>
          </template>
           <!-- COLUMN: Number -->
            <template #cell(no)="data">                      
                <!-- {{Number(data.index) + 1}} -->
                {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
            </template>
            <!-- COLUMN: STUDENT NAME  -->
            <template #cell(applicationNumber)="data">
              <div class="add_edu_link">
                <a @click.prevent="handleRowClick(data.item)">{{data.item.applicationNumber}}</a>
              </div>
            </template>
            <template #cell(studentName)="data">
              <div class="add_edu_link">
                <a @click.prevent="changeRoute(data.item)">{{data.item.studentName}}</a>
              </div>
            </template>
            <template #cell(course_name)="data">
                {{data.item.course_name.title}}
            </template>
            <!-- COLUMN: INTAKE  -->
            <template #cell(intake)="data">                      
                {{data.item.intake.toString()}}
            </template>
            <!-- COLUMN: selectedCollege -->
            <template #cell(selectedCollege)="data">
              {{data.item.selectedCollege.length}}
            </template>
            <template #cell(shortListStatus)="data">                
                <div>
                   <div class="d-flex status_top_line">
                        <ul class="d-flex align-items-center"> 
                          <li><span class="panding-bg round_small_status"></span><span>{{data.item.shortListStatus.totalPending}}</span></li>
                          <li><span class="noteligible-bg round_small_status"></span><span>{{data.item.shortListStatus.totalNotEligible}}</span></li>
                          <li><span class="inprogress-bg round_small_status"></span><span>{{data.item.shortListStatus.totalInProgress}}</span></li>
                          <li><span class="paymentPending-bg round_small_status"></span><span>{{data.item.shortListStatus.totalPayPending}}</span></li>
                          <li><span class="paymentSuccessfull-bg round_small_status"></span><span>{{data.item.shortListStatus.totalPaySuccessful}}</span></li>
                          <li><span class="applicationSubmitted-bg round_small_status"></span><span>{{data.item.shortListStatus.totalApplicationSub}}</span></li>
                          <li><span class="ad_success round_small_status"></span><span>{{data.item.shortListStatus.totalAdmissionSuccesfull}}</span></li>
                          <li><span class="ad_rejected round_small_status"></span><span>{{data.item.shortListStatus.totalAdmissionRejected}}</span></li>
                        </ul>
                    </div>
                </div>
            </template>            
            <template #cell(application_Status)="data">  
              <span>     
               <b-badge
               pill
               :class="[{'pendingCSSSClass':data.item.displayStatus === 'Payment Pending','applicationSubmitted' : data.item.displayStatus === 'Application Submitted','applicationNotEligibleStudentList' : data.item.displayStatus === 'Not Eligible'}]"
               :variant="'light-'+data.item.applicationStatusVariant"
               >
                 {{data.item.displayStatus}}
               </b-badge>   
              </span>     
                <!-- <div :class="{
                'badge badge-primary': (data.item.application_Status.toLowerCase()=='pending'), 
                'badge badge-warning': (data.item.application_Status.toLowerCase()=='inProgress'),
                'badge badge-secondary': (data.item.application_Status.toLowerCase()=='paymentPending'), 
                'badge badge-info': (data.item.application_Status.toLowerCase()=='paymentSuccessful'), 
                'badge badge-Dark': (data.item.application_Status.toLowerCase()=='applicationSubmitted'), 
                'badge badge-success': (data.item.application_Status.toLowerCase()=='admissionsuccessful'), 
                'badge badge-danger':(data.item.application_Status.toLowerCase()=='admissionrejected'
                )}">
                   {{data.item.displayStatus}}
                </div> -->
            </template>
           </b-table>    
     
      <div class="mx-2 mb-2">
      <b-row>
       <!-- PAGINATION TEXT -->
      <b-col
      cols="12"
      sm="6"
      class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ totalData }} entries</span>
      </b-col>
       <!-- PAGINATION FOOTER -->
      <b-col cols="12" class="d-flex align-items-center justify-content-center justify-content-sm-end">
        <div class="btn__manage_prevnext" style="margin-right: 40px;">
          <!--
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary mr-1"
          :disabled="previuosDisabled"
          class="btn-icon" 
          @click="handlePagination('previous')"       
        >
          Previous
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          :disabled="nextDisabled"
          class="btn-icon" 
          @click="handlePagination('next')"         
        >
          Next
        </b-button>
          -->
          <!-- <feather-icon
            class="cursor-pointer"
            @click="handlePagination('previous')"
            icon="ChevronLeftIcon"
            size="18"
          />
          <feather-icon
            class="cursor-pointer"
            @click="handlePagination('next')"
            icon="ChevronRightIcon"
            size="18"
          /> -->
        </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalData"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        @change="handlePageChange"
        next-class="next-item"/>
      </b-col>
    </b-row>
      </div>
  </div>  
</b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormCheckbox,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormGroup,BFormRadio,BFormDatepicker,
  BForm,BFormTextarea,BFormSelect,BFormCheckboxGroup,BSpinner
} from 'bootstrap-vue'
import { dbCollections } from '@/utils/firebaseCollection'
import vSelect from 'vue-select'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import moment from 'moment'
import { getCollectionData } from '@/utils/dynamicGetDataQuery'
import Ripple from 'vue-ripple-directive'
import { getCollectionDataTypesense } from '@/utils/Typesense/queries'
export default {
  directives: {
    Ripple,
  },
  components: {   
        BCard,
        BRow,
        BCol,
        BForm,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BModal,
        BFormGroup,
        BFormRadio,
        BFormDatepicker,
        BFormTextarea,
        BFormSelect,
        BFormCheckbox,
        BFormCheckboxGroup,
        BSpinner,
        vSelect,
  },
  data(){
      return{
        isSpinner:false,
        isFetching:false,

      docCategoryTable:[],
      nextDisabled:false,
      previuosDisabled:false,
      fetchMoreDataStop: false,
      sortingVal: null,
      firstDoc: null,
      lastDoc: null,
      search:'',
      allApplications:[],
      paginationNo:0,
      perPage: 10,
      pageOptions: [10,50,100],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [], 
      columns: [
        // { key: 'no', label:'No.', sortable: false },
        { key: 'applicationNumber', label:'Application No.', sortable:true },
        { key: 'studentName', label:'Student Name', sortable:true },
        { key: 'intake', label:'Intake', sortable:false },
        { key: 'selectedCollege', label:'No. of Suggested Course', sortable:false },
        { key: 'shorlisted', label:'No. of Shortlist Course', sortable:false },
        { key: 'appDate', label:'Application Date', sortable:true },
        { key: 'application_Status', label:'Application Status', sortable:true }, 
        { key: 'shortListStatus', label:''} 
      ],
      totalData:0,
      sortContect: null,
      timeFnction: null
      } 
  },
  watch: {
    $route: function () {
      this.fetchApplicationDataTypesense();
    },
    perPage: function(){
      this.fetchApplicationDataTypesense();
    },
  },
  created(){
    var self = this;
    // this.fetchApplicationData();
    self.fetchApplicationDataTypesense();
  },
  beforeDestroy(){
    // console.log('COMPONENT UNMOUNTED SUCCESSFULLT FROM APPLICATION LIST');
    this.fetchMoreDataStop = true;
    this.allApplications = [];
  },
  methods: {
    handleSortChange(context) {
        let sortFieldObject = {
            application_Status : "application_Status",
            applicationNumber : "applicationNumber",
            studentName : "selectedStudent.name",
            appDate : "createdAt",
            shorlisted : "",
            selectedCollege : "",
            intake : "",
            shortListStatus : "",
        }
        const {sortBy,sortDesc} = context
        this.sortContect = {sortBy:sortFieldObject[sortBy],sortDesc};
        this.fetchApplicationDataTypesense(false)
    },
    handleSearch() {
      clearTimeout(this.timeFnction);
      this.timeFnction = setTimeout(() => {
        this.fetchApplicationDataTypesense(false);
      }, 1000);
    },
    handlePageChange(value) {
      this.currentPage = value;
      // this.fetchApplicationData(value)
      this.fetchApplicationDataTypesense(false)
    },
    changeRoute(data){
      // console.log("data",data);
      if(this.$route.meta.resource === 'ACL') {
        this.$router.push({name:'studentDetail'});
      } else {
        this.$router.push({name:'student-information',params:{name:data.selectedStudent.name,id:data.selectedStudent.id}});
      }
    },
    fetchApplicationDataTypesense(resetResult = true){
        try {
            var self = this;
            if (resetResult) {
                self.currentPage = 1;
                self.totalData = 0;
            }

            let searchParameters = {
                collectionName: `${dbCollections.APPLICATIONS}`,
                search: {
                    q:"*",
                    page: self.currentPage,
                    per_page: self.perPage,
                }
            }

            if (this.sortContect) {
                searchParameters.search.sort_by = `${(this.sortContect.sortBy && this.sortContect.sortBy !== '') ? `${this.sortContect.sortBy}: ${this.sortContect.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
            }

            if(self.filter !== '' && self.filter !== null) {
              function chngeTextVal(searchText) {
                  const statusMap = {
                      pending: 'Pending',
                      inprogress: 'In Progress',
                      paymentpending: 'Payment Pending',
                      paymentsuccessful: 'Payment Successful',
                      applicationsubmitted: 'Application Submitted',
                      admissionsuccessful: 'Admission Offered',
                      admissionrejected: 'Rejection',
                      noteligible: 'Not Eligible'
                  };
                  for (const [key, value] of Object.entries(statusMap)) {
                      searchText = searchText.replace(new RegExp(value, 'gi'), key);
                  }
                  return searchText;
              }
              searchParameters.search.q = chngeTextVal(self.filter);
              searchParameters.search.query_by = 'applicationNumber,selectedStudent.name,application_Status';
            }

            getCollectionDataTypesense(searchParameters).then((result)=>{
                if(result.hits.length) {
                    self.totalData = result.found
                    const arrayData = result.hits.map(x => {
                        const applicationObject = x.document;

                        let shortListStatus= {
                          totalPending:0,
                          totalInProgress:0,
                          totalPayPending:0,
                          totalPaySuccessful:0,
                          totalApplicationSub:0,
                          totalAdmissionSuccesfull:0,
                          totalAdmissionRejected:0,
                          totalNotEligible:0
                        }          
                        let displayStatus = 'Pending'
                        let applicationStatusVariant = 'primary'
                        if(applicationObject.shortListedColleges !== undefined && applicationObject.shortListedColleges.length > 0){
                          applicationObject.shortListedColleges.forEach(shortlisted=>{
                            if(shortlisted.status == 'pending'){
                              shortListStatus.totalPending = Number(JSON.parse(shortListStatus.totalPending) + 1);
                            }
                            if(shortlisted.status == 'inProgress'){
                              shortListStatus.totalInProgress = Number(JSON.parse(shortListStatus.totalInProgress) + 1);
                            }
                            if(shortlisted.status == 'paymentPending'){
                              shortListStatus.totalPayPending = Number(JSON.parse(shortListStatus.totalPayPending) + 1);
                            }
                            if(shortlisted.status == 'paymentSuccessful'){
                              shortListStatus.totalPaySuccessful = Number(JSON.parse(shortListStatus.totalPaySuccessful) + 1);
                            }
                            if(shortlisted.status == 'applicationSubmitted'){
                              shortListStatus.totalApplicationSub = Number(JSON.parse(shortListStatus.totalApplicationSub) + 1);
                            }
                            if(shortlisted.status == 'admissionSuccessful'){
                              shortListStatus.totalAdmissionSuccesfull = Number(JSON.parse(shortListStatus.totalAdmissionSuccesfull) + 1);
                            }
                            if(shortlisted.status == 'admissionRejected'){
                              shortListStatus.totalAdmissionRejected = Number(JSON.parse(shortListStatus.totalAdmissionRejected) + 1);
                            }                 
                            if(shortlisted.status == 'notEligible'){
                              shortListStatus.totalNotEligible = Number(JSON.parse(shortListStatus.totalNotEligible) + 1);
                            }                 
                          })
                        } 
                        if(applicationObject.application_Status.toLowerCase()=='pending'){
                            displayStatus = 'Pending'
                            applicationStatusVariant = 'primary'
                        }
                        if(applicationObject.application_Status.toLowerCase()=='inprogress'){
                            displayStatus = 'In Progress'
                            applicationStatusVariant = 'warning'
                        }
                        if(applicationObject.application_Status.toLowerCase()=='paymentpending'){
                            displayStatus = 'Payment Pending'
                            applicationStatusVariant = 'secondary'
                        }
                        if(applicationObject.application_Status.toLowerCase()=='paymentsuccessful'){
                            displayStatus = 'Payment Successful'
                            applicationStatusVariant = 'info'
                        }
                        if(applicationObject.application_Status.toLowerCase()=='applicationsubmitted'){
                            displayStatus = 'Application Submitted'
                            applicationStatusVariant = 'dark'
                        }
                        if(applicationObject.application_Status.toLowerCase()=='admissionsuccessful'){
                            displayStatus = 'Admission Offered'
                            applicationStatusVariant = 'success'
                        }
                        if(applicationObject.application_Status.toLowerCase()=='admissionrejected'){
                            displayStatus = 'Rejection'
                            applicationStatusVariant = 'danger'
                        }
                        if(applicationObject.application_Status.toLowerCase()=='noteligible'){
                            displayStatus = 'Not Eligible'
                            applicationStatusVariant = 'dark'
                        }

                      
                        return {
                            ...applicationObject,
                            id: applicationObject.id,
                            appDate: applicationObject.createdAt ? moment(new Date(applicationObject.createdAt * 1000)).format('DD MMM, yyyy') : '',
                            studentName: applicationObject.selectedStudent.name,
                            shorlisted: applicationObject?.shortListedColleges?.length || 0,
                            shortListStatus: shortListStatus,
                            displayStatus,
                            applicationStatusVariant
                        };
                    });

                    this.allApplications = arrayData;

                } else {
                    this.allApplications = [];
                    self.totalData = 0;
                }
            }).catch(()=>{
              this.allApplications = [];
              self.totalData = 0;
              console.error(error)
            })
        } catch (error) {
            this.allApplications = [];
            self.totalData = 0;
            console.error(error)
        }
    },
    fetchApplicationData(direction = '',refetchIng=false) {
      try {
          if(refetchIng == false) {
            this.isFetching = true;
            this.allApplications = [];
          }

          let limit = 100
          let orderByValue = {field: "createdAt",value: 1}
          let refDoc;
          let refDoc1;
          let limitToLast = 0;
          if(direction == 'next') {
              orderByValue = {field: "createdAt",value: 1}
              refDoc = {
                  doc: this.lastDoc
              }
          } else if(direction == 'previous') {
              orderByValue = {field: "createdAt",value: 1}
              refDoc1 = {
                doc: this.firstDoc
              }
              limitToLast = limit
          }
          if(this.sortingVal && this.sortingVal.key!=null) {
            orderByValue = {field: this.sortingVal.key,value: this.sortingVal.value == true ? 1 : 0}
          }

          getCollectionData(`${dbCollections.APPLICATIONS}`,[{field: "isDeleted",operation: "==",value: false}], orderByValue,limit,'','',refDoc?.doc || '',refDoc1?.doc || '',limitToLast)
            .then((querySnapshot) => {
              if(querySnapshot.empty == true) {
                this.isFetching = false;
                if(direction == 'next') {
                  this.nextDisabled = true;
                  this.lastDoc = '';
                } else if(direction == 'previous') {
                  this.previuosDisabled = true;
                  this.firstDoc = '';
                }
                return;
              }
              const docs =  querySnapshot.docs?.map((x) => ({...x.data(), id: x.id}));
              let docsData =[]
              if(direction == 'next') {
                this.lastDoc = querySnapshot.docs?.[querySnapshot.docs.length - 1];
              } else if(direction == 'previous') {
                this.firstDoc = querySnapshot.docs?.[0];
              }
              docs.forEach(async(doc)=>{
                const element = doc;
                let createDate = element.createdAt;
                element.appDate = moment(createDate.toDate()).format('DD MMM, yyyy') ? moment(createDate.toDate()).format('DD MMM, yyyy') : '' 
                element.studentName = element.selectedStudent.name;             
                element.shorlisted = 0
                element.shortListStatus= {
                  totalPending:0,
                  totalInProgress:0,
                  totalPayPending:0,
                  totalPaySuccessful:0,
                  totalApplicationSub:0,
                  totalAdmissionSuccesfull:0,
                  totalAdmissionRejected:0,
                  totalNotEligible:0
                }          
                if(element.shortListedColleges !== undefined && element.shortListedColleges.length > 0){
                  element.shorlisted = element.shortListedColleges.length 
                  element.shortListedColleges.forEach(shortlisted=>{
                    if(shortlisted.status == 'pending'){
                      element.shortListStatus.totalPending = Number(JSON.parse(element.shortListStatus.totalPending) + 1);
                    }
                    if(shortlisted.status == 'inProgress'){
                      element.shortListStatus.totalInProgress = Number(JSON.parse(element.shortListStatus.totalInProgress) + 1);
                    }
                    if(shortlisted.status == 'paymentPending'){
                      element.shortListStatus.totalPayPending = Number(JSON.parse(element.shortListStatus.totalPayPending) + 1);
                    }
                    if(shortlisted.status == 'paymentSuccessful'){
                      element.shortListStatus.totalPaySuccessful = Number(JSON.parse(element.shortListStatus.totalPaySuccessful) + 1);
                    }
                    if(shortlisted.status == 'applicationSubmitted'){
                      element.shortListStatus.totalApplicationSub = Number(JSON.parse(element.shortListStatus.totalApplicationSub) + 1);
                    }
                    if(shortlisted.status == 'admissionSuccessful'){
                      element.shortListStatus.totalAdmissionSuccesfull = Number(JSON.parse(element.shortListStatus.totalAdmissionSuccesfull) + 1);
                    }
                    if(shortlisted.status == 'admissionRejected'){
                      element.shortListStatus.totalAdmissionRejected = Number(JSON.parse(element.shortListStatus.totalAdmissionRejected) + 1);
                    }                 
                    if(shortlisted.status == 'notEligible'){
                      element.shortListStatus.totalNotEligible = Number(JSON.parse(element.shortListStatus.totalNotEligible) + 1);
                    }                 
                  })
                } 
                  if(element.application_Status.toLowerCase()=='pending'){
                      element.displayStatus = 'Pending'
                      element.applicationStatusVariant = 'primary'
                  }
                  if(element.application_Status.toLowerCase()=='inprogress'){
                      element.displayStatus = 'In Progress'
                      element.applicationStatusVariant = 'warning'
                  }
                  if(element.application_Status.toLowerCase()=='paymentpending'){
                      element.displayStatus = 'Payment Pending'
                      element.applicationStatusVariant = 'secondary'
                  }
                  if(element.application_Status.toLowerCase()=='paymentsuccessful'){
                      element.displayStatus = 'Payment Successful'
                      element.applicationStatusVariant = 'info'
                  }
                  if(element.application_Status.toLowerCase()=='applicationsubmitted'){
                      element.displayStatus = 'Application Submitted'
                      element.applicationStatusVariant = 'dark'
                  }
                  if(element.application_Status.toLowerCase()=='admissionsuccessful'){
                      element.displayStatus = 'Admission Offered'
                      element.applicationStatusVariant = 'success'
                  }
                  if(element.application_Status.toLowerCase()=='admissionrejected'){
                      element.displayStatus = 'Rejection'
                      element.applicationStatusVariant = 'danger'
                  }
                  if(element.application_Status.toLowerCase()=='noteligible'){
                      element.displayStatus = 'Not Eligible'
                      element.applicationStatusVariant = 'dark'
                  }
                  docsData.push(element)
                  if(docsData.length === docs.length) {
                    this.allApplications = [...this.allApplications,...docsData]
                    this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
                    this.firstDoc = querySnapshot.docs[0];
                    if (this.nextDisabled === false && this.fetchMoreDataStop == false) {
                      this.fetchApplicationData('next',true);
                    }
                  }
                  this.paginationNo = this.allApplications.length;
              })
                this.isFetching = false;
            })
            .catch((error) => {
                this.isFetching = false;
                console.error("Error in fetchApplicationData", error);
            });
      } catch (error) {
          this.isFetching = false;
          console.error("Error in fetchApplicationData", error);
      }
    },
    sortChanged(e) {
        const sortingKeyMap = {
            'applicationNumber': 'applicationNumber',
            'appDate': 'createdAt',
        };

        const sortKey = sortingKeyMap[e.sortBy];

        if (e.sortDesc) {
          if(sortKey == 'applicationNumber') {
            this.allApplications.sort((b,a)=>(+a.applicationNumber) - (+b.applicationNumber))
          }
          if(sortKey == 'createdAt') {
            this.allApplications.sort((b,a)=>a.createdAt.seconds - b.createdAt.seconds)
          }
        } else {
          if(sortKey == 'applicationNumber') {
            this.allApplications.sort((a,b)=>(+a.applicationNumber) - (+b.applicationNumber))
          }
          if(sortKey == 'createdAt') {
            this.allApplications.sort((a,b)=>a.createdAt.seconds - b.createdAt.seconds)
          }
        }
    },
    getStudentData(data,cb){
      getQuery.getDataFromRootCollectionWithDocID(dbCollections.USERS,data.selectedStudent.id,res=>{
        cb(res)
      })
    },
    onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
            if(this.filter !== ""){
              this.paginationNo = this.docCategoryTable.length
            }else{
              this.paginationNo = this.allApplications.length
            }
    },
    handleRowClick(data)
        {
        this.$router.push({name:'application-mgmt-Info',params: { id: data.id,name:data.studentName }})
        },  
  },
  computed:{
    // getFilterList()
    // {
    //   var self = this

    //   var tmp = self.allApplications

    //   return tmp;
    // },
    dataMeta(){
        const localItemsCount = this.docCategoryTable ? this.docCategoryTable.length : 0
              return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.allApplications.length,
              }
    }  
  }  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.paddingBackGround{
    background-color:#6255eb;
    color: white;
    padding: 8px 10px !important;
    border-radius: 10px !important;
}
.successBackGround{
    background-color:#1cc06b;
    color: white;
    padding: 8px 10px !important;
    border-radius: 10px !important;
}
.progressBackGround{
    background-color: #ffa045;
    color: black;
    padding: 8px 10px !important;
    border-radius: 10px !important;
}
.rejectedBackGround{
    background-color: #fc1817;
    color: white;
    padding: 8px 10px !important;
    border-radius: 10px !important;
}
span.badge.badge-light-secondary.badge-pill.pendingCSSSClass {
    background-color: #DCDCDC !important;
    color: #82868B !important;
    border: none;
}
span.badge.badge-light-dark.badge-pill.applicationSubmitted {
    background-color: rgba(75, 75, 75, 0.12) !important;
    color: #4b4b4b !important;
    border: none;
}
.btn__manage_prevnext .btn-outline-primary:not(:disabled):not(.disabled):focus {
    background-color: transparent;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.applicationNotEligibleStudentList {
    border-color: #dfecec!important;
    background-color: #dfecec!important;
    color: #396060!important;
    padding: 8px 10px !important;
    border-radius: 10px !important;
}
</style>
